import { NewsItem } from 'interfaces/News/News';
import { useRouter } from 'next/router';
import { useMemo } from 'react';

const articles = [
  {
    previewImage: 'tripod/tripod-main-image.jpg',
    id: 'tripod',
    link: 'tripod-for-using-idchess',
    date: [17, 1, 2024],
    filters: ['instructions'],
    lang: ['ru', 'en'],
  },
  {
    previewImageLocale: {
      ru: 'smartphone/smartphone-main-image.jpg',
      en: 'smartphone/smartphone-main-image-en.jpg',
    },
    id: 'smartphone',
    link: 'smartphone-for-using-idchess',
    date: [26, 1, 2024],
    filters: ['instructions'],
    lang: ['ru', 'en'],
  },
  {
    previewImage: 'var_history/var_history-main-image.jpg',
    id: 'var_history',
    link: 'var-in-different-kinds-of-sport',
    date: [9, 2, 2024],
    filters: ['stories'],
    lang: ['ru', 'en'],
  },
];

export const sortedArticles = articles => {
  if (!articles?.length) {
    return [];
  }

  return articles.sort((prev, next) => {
    if (
      !prev?.date ||
      !next?.date ||
      !(prev.date?.length >= 3 && next.date?.length >= 3)
    ) {
      return 0;
    }

    const prevDate = new Date(prev.date[2], prev.date[1], prev.date[0]);
    const nextDate = new Date(next.date[2], next.date[1], next.date[0]);

    if (nextDate > prevDate) {
      return 1;
    }

    if (nextDate < prevDate) {
      return -1;
    }

    return 0;
  });
};

export const articlesFilters = [
  // фильтры по которым пока нет статей закомментированы, чтобы они не отображались на странице
  'instructions',
  // 'tips',
  'stories',
  // 'tournaments',
];

const articlesContent = {
  common: {
    tripod: [
      {
        type: 'image',
        image: 'tripod/tripod-main-image.jpg',
        alt: 'blog.items.tripod.title',
      },
      {
        type: 'subtitle',
        text: ['blog.items.tripod.subtitle.0'],
      },
      {
        type: 'h2',
        title: 'blog.items.tripod.subtitle.1',
      },
      {
        type: 'text',
        text: ['blog.items.tripod.text.0'],
      },
      {
        type: 'h2',
        title: 'blog.items.tripod.subtitle.2',
      },
      {
        type: 'slider',
        images: ['tripod/tripod-slider1-1.jpg', 'tripod/tripod-slider1-2.jpg'],
      },
      {
        type: 'text',
        text: ['blog.items.tripod.text.1'],
      },
      {
        type: 'h2',
        title: 'blog.items.tripod.subtitle.3',
      },
      {
        type: 'imagesLine',
        images: ['tripod/tripod-pack-1.jpg', 'tripod/tripod-pack-2.jpg'],
      },
      {
        type: 'imagesLine',
        images: ['tripod/tripod-pack-3.jpg', 'tripod/tripod-pack-4.jpg'],
      },
      {
        type: 'text',
        text: ['blog.items.tripod.text.2'],
      },
      {
        type: 'h2',
        title: 'blog.items.tripod.subtitle.4',
      },
      {
        type: 'slider',
        images: ['tripod/tripod-slider2-1.jpg', 'tripod/tripod-slider2-2.jpg'],
      },
      {
        type: 'text',
        textWithLink: [
          [
            {
              text: 'blog.items.tripod.text.3',
            },
            {
              text: 'blog.items.tripod.text.4',
              link: 'https://idchess.com/ru/products/16',
            },
            {
              text: 'blog.items.tripod.text.5',
            },
          ],
        ],
      },
      {
        type: 'text',
        text: ['blog.items.tripod.text.6'],
      },
      {
        type: 'text',
        textWithLink: [
          [
            {
              text: 'blog.items.tripod.text.7',
            },
            {
              text: 'blog.items.tripod.text.8',
            },
            {
              text: 'blog.items.tripod.text.9',
              link: 'mailto:suppot@idchess.com',
            },
          ],
        ],
      },
    ],
    smartphone: [
      {
        type: 'image',
        imageLocale: {
          ru: 'smartphone/smartphone-main-image.jpg',
          en: 'smartphone/smartphone-main-image-en.jpg',
        },
        alt: 'blog.items.smartphone.title',
      },
      {
        type: 'subtitle',
        text: ['blog.items.smartphone.subtitle.0'],
      },
      {
        type: 'text',
        text: ['blog.items.smartphone.text.0'],
      },
      {
        type: 'h2',
        title: 'blog.items.smartphone.subtitle.1',
      },
      {
        type: 'image',
        imageLocale: {
          ru: 'smartphone/smartphone-1.jpg',
          en: 'smartphone/smartphone-1-en.jpg',
        },
        alt: 'blog.items.smartphone.subtitle.1',
      },
      {
        type: 'subtitle',
        text: ['blog.items.smartphone.subtitle.2'],
      },
      {
        type: 'text',
        text: ['blog.items.smartphone.text.1', 'blog.items.smartphone.text.2'],
      },
      {
        type: 'subtitle',
        text: ['blog.items.smartphone.subtitle.3'],
      },
      {
        type: 'text',
        text: ['blog.items.smartphone.text.3'],
      },
      {
        type: 'subtitle',
        text: ['blog.items.smartphone.subtitle.4'],
      },
      {
        type: 'text',
        text: ['blog.items.smartphone.text.4'],
      },
      {
        type: 'subtitle',
        text: ['blog.items.smartphone.subtitle.5'],
      },
      {
        type: 'text',
        text: ['blog.items.smartphone.text.5'],
      },
      {
        type: 'subtitle',
        text: ['blog.items.smartphone.subtitle.6'],
      },
      {
        type: 'text',
        text: ['blog.items.smartphone.text.6'],
      },
      {
        type: 'subtitle',
        text: ['blog.items.smartphone.subtitle.7'],
      },
      {
        type: 'text',
        text: ['blog.items.smartphone.text.7'],
      },
      {
        type: 'results',
        isBold: true,
        line: true,
        results: [
          {
            text: 'blog.items.smartphone.results.options.0.name',
            rating: 'blog.items.smartphone.results.options.0.rating',
          },
          {
            text: 'blog.items.smartphone.results.options.1.name',
            rating: 'blog.items.smartphone.results.options.1.rating',
          },
        ],
      },
      {
        type: 'h2',
        title: 'blog.items.smartphone.subtitle.8',
      },
      {
        type: 'image',
        imageLocale: {
          ru: 'smartphone/smartphone-2.jpg',
          en: 'smartphone/smartphone-2-en.jpg',
        },
        alt: 'blog.items.smartphone.subtitle.8',
      },
      {
        type: 'text',
        text: [
          'blog.items.smartphone.text.8',
          'blog.items.smartphone.text.9',
          'blog.items.smartphone.text.10',
        ],
      },
    ],
    var_history: [
      {
        type: 'image',
        image: 'var_history/var_history-main-image.jpg',
        alt: 'blog.items.var_history.title',
      },
      {
        type: 'subtitle',
        text: ['blog.items.var_history.subtitle.0'],
      },
      {
        type: 'h2',
        title: 'blog.items.var_history.subtitle.1',
      },
      {
        type: 'image',
        image: 'var_history/var_history-1.jpg',
        alt: 'blog.items.var_history.subtitle.1',
      },
      {
        type: 'text',
        text: [
          'blog.items.var_history.text.0',
          'blog.items.var_history.text.1',
          'blog.items.var_history.text.2',
        ],
      },
      {
        type: 'h2',
        title: 'blog.items.var_history.subtitle.2',
      },
      {
        type: 'image',
        image: 'var_history/var_history-2.jpg',
        alt: 'blog.items.var_history.subtitle.2',
      },
      {
        type: 'text',
        text: [
          'blog.items.var_history.text.3',
          'blog.items.var_history.text.4',
          'blog.items.var_history.text.5',
        ],
      },
      {
        type: 'h2',
        title: 'blog.items.var_history.subtitle.3',
      },
      {
        type: 'image',
        image: 'var_history/var_history-3.jpg',
        alt: 'blog.items.var_history.subtitle.3',
      },
      {
        type: 'text',
        text: [
          'blog.items.var_history.text.6',
          'blog.items.var_history.text.7',
          'blog.items.var_history.text.8',
        ],
      },
      {
        type: 'h2',
        title: 'blog.items.var_history.subtitle.4',
      },
      {
        type: 'image',
        image: 'var_history/var_history-4.jpg',
        alt: 'blog.items.var_history.subtitle.4',
      },
      {
        type: 'text',
        text: [
          'blog.items.var_history.text.9',
          'blog.items.var_history.text.10',
        ],
      },
      {
        type: 'h2',
        title: 'blog.items.var_history.subtitle.5',
      },
      {
        type: 'text',
        text: ['blog.items.var_history.text.11'],
      },
      {
        type: 'h2',
        title: 'blog.items.var_history.subtitle.6',
      },
      {
        type: 'image',
        image: 'var_history/var_history-5.jpg',
        alt: 'blog.items.var_history.subtitle.5',
      },
      {
        type: 'text',
        textWithLink: [
          [
            {
              text: 'blog.items.var_history.text.12',
            },
            {
              text: 'blog.items.var_history.text.13',
              link: 'https://www.fide.com/news/2491',
            },
            {
              text: 'blog.items.var_history.text.14',
            },
          ],
        ],
      },
      {
        type: 'text',
        text: ['blog.items.var_history.text.15'],
      },
      {
        type: 'image',
        image: 'var_history/var_history-6.jpg',
        alt: 'blog.items.var_history.subtitle.6',
      },
      {
        type: 'text',
        text: ['blog.items.var_history.text.16'],
      },
      {
        type: 'image',
        image: 'var_history/var_history-7.jpg',
        alt: 'blog.items.var_history.subtitle.7',
      },
      {
        type: 'text',
        text: ['blog.items.var_history.text.17'],
      },
      {
        type: 'h2',
        title: 'blog.items.var_history.subtitle.7',
      },
      {
        type: 'text',
        text: ['blog.items.var_history.text.18'],
      },
    ],
  },
  en: {
    tripod: [
      {
        type: 'image',
        image: 'tripod/tripod-main-image.jpg',
        alt: 'blog.items.tripod.title',
      },
      {
        type: 'subtitle',
        text: ['blog.items.tripod.subtitle.0'],
      },
      {
        type: 'h2',
        title: 'blog.items.tripod.subtitle.1',
      },
      {
        type: 'text',
        text: ['blog.items.tripod.text.0'],
      },
      {
        type: 'h2',
        title: 'blog.items.tripod.subtitle.2',
      },
      {
        type: 'slider',
        images: [
          'tripod/tripod-slider1-1-en.jpg',
          'tripod/tripod-slider1-2.jpg',
        ],
      },
      {
        type: 'text',
        text: ['blog.items.tripod.text.1', 'blog.items.tripod.text.2'],
      },
      {
        type: 'h2',
        title: 'blog.items.tripod.subtitle.3',
      },
      {
        type: 'imagesLine',
        images: ['tripod/tripod-pack-1.jpg', 'tripod/tripod-pack-2.jpg'],
      },
      {
        type: 'imagesLine',
        images: ['tripod/tripod-pack-3.jpg', 'tripod/tripod-pack-4-en.jpg'],
      },
      {
        type: 'text',
        text: ['blog.items.tripod.text.3'],
      },
      {
        type: 'text',
        textWithLink: [
          [
            {
              text: 'blog.items.tripod.text.4',
            },
            {
              text: 'blog.items.tripod.text.5',
            },
            {
              text: 'blog.items.tripod.text.6',
              link: 'mailto:suppot@idchess.com',
            },
          ],
        ],
      },
    ],
  },
};

export const getArticle = id => {
  const currentItem = articles.filter(item => item.link === id)[0];

  if (currentItem) {
    const data: NewsItem = {
      ...currentItem,
    };

    return {
      status: 200,
      data: data,
    };
  }

  return {
    status: 404,
  };
};

export const useGetCurrentArticles = () => {
  const router = useRouter();

  const { locale: currentLanguage } = router as {
    locale: string;
  };

  const currentArticles = useMemo(
    () => articles?.filter(item => item.lang?.includes(currentLanguage)),
    [articles, currentLanguage]
  );

  const currentArticlesContent = useMemo(() => {
    return Object.keys(articlesContent.common)
      ?.filter(key => currentArticles.map(({ id }) => id).includes(key))
      .reduce((obj, key) => {
        obj[key] =
          articlesContent[currentLanguage]?.[key] ||
          articlesContent.common[key];
        return obj;
      }, {});
  }, [articlesContent, currentArticles]);

  return { articles: currentArticles, articlesContent: currentArticlesContent };
};
