import React, { useContext, useRef } from 'react';
import cn from 'classnames';
import { AppContext } from 'context/app.context';
import { MODAL } from 'helpers/constants/modals';

import Modal from 'components/blocks/Modal';
import YouTube, { YouTubeProps } from 'react-youtube';

import styles from './ModalVideo.module.scss';

interface ModalProps {
  video: string;
  isYoutube?: boolean;
}

const ModalVideo: React.FC<ModalProps> = ({ video, isYoutube = false }) => {
  const { modalType, setModalType } = useContext(AppContext);
  const videoContainer = useRef(null);

  const youtubeOpts = {
    height: '100%',
    width: '100%',
    playerVars: {
      autoplay: 1,
      showinfo: 0,
      enablejsapi: 1,
      playsinline: 1,
      rel: 0,
    },
  };

  const onYoutubeReady: YouTubeProps['onReady'] = event => {
    event.target.playVideo();
  };

  const onVideoOpen = () => {
    if (!videoContainer.current) {
      return;
    }

    videoContainer.current.play();

    if (videoContainer.current.paused) {
      videoContainer.current.controls = true;
    }
  };

  return (
    <Modal
      isOpen={modalType === MODAL.video && video !== ''}
      onAfterOpen={onVideoOpen}
    >
      {!isYoutube && (
        <video
          src={`${video}#t=0.001`}
          playsInline={true}
          loop={true}
          ref={videoContainer}
          className={styles.modalVideo}
        />
      )}
      {isYoutube && (
        <div className={styles.modalVideo__youtubeContainer}>
          <YouTube
            videoId={video}
            opts={youtubeOpts}
            onReady={onYoutubeReady}
            className={styles.modalVideo__youtubeVideo}
          />
        </div>
      )}
      <div
        className={cn('closeButton', styles.modalVideo__closeButton)}
        onClick={() => setModalType('')}
      />
    </Modal>
  );
};

export default ModalVideo;
